import React, { Component } from 'react'
import Loader from 'react-loader-spinner'

export default class EPCContainer extends Component {
    state = {
      errorMsg: '',
      showLoader: true,
    }

    async componentDidMount() {
      window.elli.script.connect()
      const transactionObject = await window.elli.script.getObject('transaction')
      const origin_data = await transactionObject.getOrigin()

      let ellie_mae_microservices_backend_url = 'https://c29a-98-217-194-103.ngrok.io/v1/ellie/'

      if (process.env.REACT_APP_ELLIE_MAE_ENV === 'staging') {
        ellie_mae_microservices_backend_url = 'https://elliemaeapi-staging.reggora.com/v1/ellie/'
      } else if (process.env.REACT_APP_ELLIE_MAE_ENV === 'production') {
        ellie_mae_microservices_backend_url = 'https://elliemaeapi.reggora.com/v1/ellie/'
      } else {
        ellie_mae_microservices_backend_url = 'https://elliemaeapi-dev.reggora.com/v1/ellie/'
      }

      if (process.env.REACT_APP_ELLIE_MAE_ENV !== 'production') {
        console.log('Backend_url: ' + ellie_mae_microservices_backend_url + ' Environment: ' + process.env.REACT_APP_ELLIE_MAE_ENV)
      }

      try {
        const loanResponse = await fetch(ellie_mae_microservices_backend_url, {
          method: 'post',
          body: JSON.stringify({
            partner_access_token: origin_data.partnerAccessToken,
            origin_data_id: origin_data.id,
          }),
          crossDomain: true,
          mode: 'cors',
        })

        if (!loanResponse.ok) {
          this.setErrorMessage()
          return
        }

        const data = await loanResponse.json()
        if ('error' in data) {
          this.setState({
            errorMsg: data.error,
            showLoader: false,
          })
        } else {
          window.location.replace(data.loan_url)
        }
      } catch (e) {
        console.log(JSON.stringify(e))
        this.setErrorMessage()
      }
    }

    setErrorMessage() {
      this.setState({
        errorMsg: 'Error fetching the loan information, please try again',
        showLoader: false,
      })
    }

    render() {
      return (
            <div>
                <h2>Syncing with Reggora...</h2>
                <h4 style={{ color: '#dc3545' }}>{this.state.errorMsg}</h4>
                { this.state.showLoader
                  ? <Loader type="Puff" color="#00BFFF" height={100} width={100}/>
                  : null
                }
            </div>
      )
    }
}
