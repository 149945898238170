import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import EPCContainer from './components/EPCContainer'

function App() {
  return (
    <div data-testid='main-component' className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={EPCContainer} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
